import { toSupabaseClient } from '@maestro/supabase';

const toNodeId = () => `studio-${window.crypto.randomUUID()}`;

type ImageSize = {
  width: number;
  height: number;
};

export type UploadedImage = ImageSize & {
  path: string;
};

type FileUploader = {
  uploadImage: (image: File) => Promise<UploadedImage | undefined>;
  uploadFromUrl: (url: string) => Promise<UploadedImage | undefined>;
};

export const useUploadImage = (path: string): FileUploader => {
  async function toDataURL(url: string): Promise<Blob> {
    const res = await fetch(url);

    return res.blob();
  }

  function getExtension(file: string): string {
    const ext = file.split('.');

    return ext[ext.length - 1];
  }

  async function upload(fileName: string, image: Blob | File) {
    const client = toSupabaseClient();
    const { data, error } = await client.storage
      .from('images')
      .upload(`${path}/${fileName}`, image);

    if (!data || error) {
      // eslint-disable-next-line no-console
      console.error('Error uploading image', error);

      return undefined;
    }

    const {
      data: { publicUrl },
    } = client.storage.from('images').getPublicUrl(data.path);

    const { width, height } = await new Promise<ImageSize>((resolve) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.width, height: img.height });
      img.src = publicUrl;
    });

    return { path: publicUrl, width, height };
  }

  const uploadImage = async (image: File) => {
    const extension = getExtension(image.name);
    const fileName = `image-${toNodeId()}.${extension}`;

    return upload(fileName, image);
  };

  const uploadFromUrl = async (url: string) => {
    const extension = getExtension(url);
    const fileName = `image-${toNodeId()}.${extension}`;
    const dataUrl = await toDataURL(url);

    return upload(fileName, dataUrl);
  };

  return { uploadFromUrl, uploadImage };
};
