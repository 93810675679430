import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';

export const useFeatureFlagEvaluation = () => {
  const flags = useFlags();

  const getFlagEvaluation = useCallback(
    (flagKey: string) => {
      if (flagKey in flags) {
        return flags[flagKey];
      }

      return false;
    },
    [flags],
  );

  return {
    getFlagEvaluation,
  };
};
