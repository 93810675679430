import { Button } from '@chakra-ui/react';
import { EpisodePublishButton } from '@maestro/cms/resources/episode/components/EpisodePublishButton';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuButton, MenuList } from '../menu/Menu';
import { CopyEpisodeShareLinkMenuItem } from './CopyEpisodeShareLinkMenuItem';
import { PlayOnAppMenuItem } from './PlayOnAppMenuItem';

type Props = {
  isPublished?: boolean;
  episodeId: string;
  episodeTitle: string;
  episodeCoverUrl: string;
  canPublish: boolean;
  variant?: string;
};

export const ShareButton: FC<Props> = ({
  episodeId,
  episodeCoverUrl,
  episodeTitle,
  canPublish,
  variant = 'default',
}) => {
  const navigate = useNavigate();

  return (
    <Menu>
      <MenuButton>
        <Button variant={variant}>Share</Button>
      </MenuButton>
      <MenuList>
        <PlayOnAppMenuItem episodeId={episodeId} />
        <CopyEpisodeShareLinkMenuItem episodeId={episodeId} />
        {canPublish && (
          <EpisodePublishButton
            episodeId={episodeId}
            episodeCoverUrl={episodeCoverUrl}
            episodeTitle={episodeTitle}
            onPublish={() => navigate(0)}
          />
        )}
      </MenuList>
    </Menu>
  );
};
