import { Button } from '@chakra-ui/react';
import { Icon } from '@maestro/components';
import { useFeatureFlagEvaluation } from '@maestro/feature-flags';
import { useUserPreferencesStore } from '@maestro/studio/state/userPreferences';
import { dimensions, rawDimensions } from '@maestro/styles';
import styled from 'styled-components';
import { OnboardingScreen } from './OnboardingScreen';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const isAndroid = /(Android)/i.test(navigator.userAgent);

export const MobileLanding: React.FC = () => {
  const { getFlagEvaluation } = useFeatureFlagEvaluation();
  const isMobileLandingEnabled = getFlagEvaluation('ENI-886');
  const { bypassMobileLanding, togglePreference } = useUserPreferencesStore(
    ({ bypassMobileLanding, togglePreference }) => ({
      bypassMobileLanding,
      togglePreference,
    }),
  );

  if (!isMobileLandingEnabled || !isMobile || bypassMobileLanding) {
    return null;
  }

  return (
    <Container>
      <OnboardingScreen
        titleText="Creating on Avenue Studio is best done on desktop"
        subtitleText="Open the link on your computer to create your own interactive story."
      >
        <ButtonContainer>
          {!isAndroid && (
            <Link href="https://share.downavenue.com/">
              <Button variant="primary" width="100%">
                Download our app
              </Button>
            </Link>
          )}
          <Link href="https://downavenue.com/create">
            <Button
              variant="default"
              width="100%"
              rightIcon={<Icon name="open" size={rawDimensions.size16} />}
            >
              Learn more
            </Button>
          </Link>
          <Button
            variant="transparent"
            onClick={() => {
              togglePreference('bypassMobileLanding', true);
            }}
          >
            Continue to mobile web
          </Button>
        </ButtonContainer>
      </OnboardingScreen>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size12};
  align-items: center;
  justify-content: center;
  max-width: ${dimensions.size400};
  margin: ${dimensions.size0} auto;
`;

const Link = styled.a`
  width: 100%;
`;
