import { Button, useDisclosure } from '@chakra-ui/react';
import { Icon, MenuItem, useMaestroToast } from '@maestro/components';
import { useFeatureFlagEvaluation } from '@maestro/feature-flags';
import { dimensions, rawDimensions } from '@maestro/styles';
import { toSupabaseClient } from '@maestro/supabase';
import React, { useState } from 'react';
import styled from 'styled-components';
import { EpisodeModal } from './EpisodeModal';
import { EpisodePublishButtonV2 } from './EpisodePublishButtonV2';

type Props = {
  episodeId: string;
  episodeTitle: string;
  episodeCoverUrl: string;
  onPublish?: () => void;
  variant?: 'menu-item' | 'button';
};

export const EpisodePublishButton: React.FC<Props> = ({
  variant = 'menu-item',
  episodeId,
  episodeTitle,
  episodeCoverUrl,
  ...props
}) => {
  const [isPublishingEpisode, setIsPublishingEpisode] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useMaestroToast();

  const { getFlagEvaluation } = useFeatureFlagEvaluation();

  const onPublishClick = async () => {
    try {
      setIsPublishingEpisode(true);

      const client = toSupabaseClient();

      const { data, error } = await client
        .from('episode_flow_version')
        .select('studio_flow_state, id')
        .eq('episode_id', episodeId)
        .is('published_at', null)
        .order('created_at', {
          ascending: true,
        })
        .single();

      if (!data || error) {
        // TODO[logrocket]: track a logrocket error event here
        toast({
          status: 'warning',
          title: 'Error publishing episode',
          description: 'There was an error while publishing the episode',
        });

        return;
      }

      if (data.studio_flow_state === null) {
        toast({
          status: 'warning',
          title: 'Episode not published',
          description: "This episode doesn't have content.",
        });

        return;
      }

      const publishedAt = new Date();

      // NOTE: convert the existing draft version to a published version
      await client
        .from('episode_flow_version')
        .update({
          published_at: publishedAt,
        })
        .eq('id', data.id);

      // NOTE: create a new draft version using the last draft studio flow state
      await client.from('episode_flow_version').insert({
        published_at: null,
        studio_flow_state: data.studio_flow_state,
        episode_id: episodeId,
      });

      onClose();

      toast({
        status: 'success',
        title: 'Episode is now published',
        description: 'The episode is now public to play on the Avenue app',
        icon: 'publish',
      });

      props?.onPublish?.();
    } catch (error) {
      toast({
        status: 'warning',
        title: 'Episode failed to publish',
        description: 'Oops! Something went wrong while publishing the episode',
      });
    } finally {
      setIsPublishingEpisode(false);
    }
  };

  if (getFlagEvaluation('NEW-PUBLISH-FLOW')) {
    return <EpisodePublishButtonV2 episodeId={episodeId} variant={variant} />;
  }

  return (
    <>
      <EpisodeModal
        episodeCoverUrl={episodeCoverUrl}
        title="Publish episode"
        operation="Publish episode"
        onClose={onClose}
        isOpen={isOpen}
        onConfirm={onPublishClick}
        isLoading={isPublishingEpisode}
        description={
          <>
            You are publishing <strong>Episode: {episodeTitle}</strong> to the
            app. Once it’s published, this episode will be available for the
            public to view in the Avenue app.
          </>
        }
      />
      {variant === 'menu-item' ? (
        <StyledMenuItem disableHover>
          <Button
            width="100%"
            variant="primary"
            onClick={onOpen}
            leftIcon={<Icon name="publish" size={rawDimensions.size16} />}
          >
            Publish
          </Button>
        </StyledMenuItem>
      ) : (
        <Button
          width="100%"
          variant="primary"
          onClick={onOpen}
          leftIcon={<Icon name="publish" size={rawDimensions.size16} />}
        >
          Publish
        </Button>
      )}
    </>
  );
};

const StyledMenuItem = styled(MenuItem)`
  border-top: ${dimensions.size1} solid
    ${({ theme }) => theme.colors.border.default[100]};
`;
