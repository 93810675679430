import { Spinner } from '@chakra-ui/react';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';

type Props = {
  onFileSelected(file: File): void;
  isLoading?: boolean;
  isInvalid?: boolean;
};

const stop = (e: React.MouseEvent<unknown>) => {
  e.preventDefault();
  e.stopPropagation();
};

const ALLOWED_FILE_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.webp', '.gif'];

export const ImageSelector: React.FC<Props> = ({
  onFileSelected,
  isLoading,
  isInvalid,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState<string | null>(null);

  async function getImageSize(
    file: File | string,
  ): Promise<{ width: number; height: number }> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = typeof file === 'string' ? file : URL.createObjectURL(file);
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  }

  const validateImage = async (image: File | string) => {
    const { width } = await getImageSize(image);

    if (width < 300) {
      setError(
        'Hmm this image is too small. Make sure your images are at least 300 pixels',
      );

      return false;
    }

    return true;
  };

  const chooseFile = (e: React.MouseEvent<unknown>) => {
    stop(e);
    fileInputRef.current?.click();
  };

  const handleDrop = async function (e: React.DragEvent<unknown>) {
    stop(e);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      await handleSelectedFile(e.dataTransfer.files[0]);
    }
  };

  const handleSelectedFile = async (file: File) => {
    setError(null);

    const isValid = await validateImage(file);

    if (isValid) {
      const reader = new FileReader();

      reader.onload = () => onFileSelected(file);
      reader.readAsDataURL(file);
    }
  };

  const handleDrag = function (e: React.DragEvent<unknown>) {
    stop(e);

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const onFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      await handleSelectedFile(e.target.files[0] as File);
    }
  };

  if (isLoading) {
    return (
      <OuterContainer>
        <Container>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          <CallToAction>Uploading image...</CallToAction>
        </Container>
      </OuterContainer>
    );
  }

  return (
    <OuterContainer
      onDrop={handleDrop}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      $dragActive={dragActive}
      $isInvalid={!!error || isInvalid}
      onClick={() => setError(null)}
    >
      <HiddenInput
        ref={fileInputRef}
        onChange={onFileInputChange}
        type="file"
        multiple={false}
        accept={ALLOWED_FILE_EXTENSIONS.join(',')}
      />

      <Container>
        <IconContainer>
          <Icon
            name={error ? 'warning' : 'image'}
            size={rawDimensions.size24}
          />
        </IconContainer>

        <TextContainer>
          <CallToAction>
            {error ?? (
              <>
                Drag and drop or{' '}
                <Anchor href="#" onClick={chooseFile}>
                  choose image
                </Anchor>{' '}
                to upload
              </>
            )}
          </CallToAction>
          <Tip>We recommend using high quality .jpg files less than 2mb</Tip>
        </TextContainer>
      </Container>
    </OuterContainer>
  );
};

const OuterContainer = styled.div<{
  $dragActive?: boolean;
  $isInvalid?: boolean;
}>`
  display: flex;
  height: 100%;
  padding: ${dimensions.size4};
  background: ${({ theme, $isInvalid }) =>
    $isInvalid ? theme.colors.base.red['100'] : 'transparent'};
  border: ${dimensions.size1} solid
    ${({ theme, $dragActive, $isInvalid }) =>
      $isInvalid
        ? theme.colors.base.red['1000']
        : $dragActive
          ? theme.colors.base.accent['1000']
          : theme.colors.base.light['100']};
  border-radius: ${dimensions.size8};

  & * {
    pointer-events: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size16};
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${dimensions.size16};
  border-radius: ${dimensions.size8};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const IconContainer = styled.div`
  padding: ${dimensions.size16} ${dimensions.size16} ${dimensions.size12}
    ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const SpinnerContainer = styled(IconContainer)`
  padding-bottom: ${dimensions.size8};
`;

const HiddenInput = styled.input`
  display: none;
`;

const CallToAction = styled.div`
  ${textStyles.body.b14m}
  color: ${({ theme }) => theme.colors.text.body};
  text-align: center;
`;

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.text.accent};
  pointer-events: auto;
  cursor: pointer;
`;

const Tip = styled.div`
  ${textStyles.body.b12m}
  color: ${({ theme }) => theme.colors.text.placeholder};
  text-align: center;
`;
