import { StatBlockLayout } from '@common/studio-types';
import { dimensions, textStyles } from '@maestro/styles';
import React from 'react';
import styled from 'styled-components';
import { CompactIcon } from './icons/CompactIcon';
import { FullWidthIcon } from './icons/FullWidthIcon';

type Props = {
  value: StatBlockLayout;
  onChange: (value: StatBlockLayout) => void;
};

export const LayoutRadioSelector: React.FC<Props> = ({ value, onChange }) => (
  <Container>
    <RadioContainer onClick={() => onChange(StatBlockLayout.Compact)}>
      <RadioIcon $selected={value === StatBlockLayout.Compact}>
        <CompactIcon />
      </RadioIcon>
      <RadioLabel>Compact</RadioLabel>
    </RadioContainer>
    <RadioContainer onClick={() => onChange(StatBlockLayout.FullWidth)}>
      <RadioIcon $selected={value === StatBlockLayout.FullWidth}>
        <FullWidthIcon />
      </RadioIcon>
      <RadioLabel>Full Width</RadioLabel>
    </RadioContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  justify-content: stretch;
  width: 100%;
  padding: ${dimensions.size4};
  border-radius: ${dimensions.size8};
`;

const RadioContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${dimensions.size12};
  align-items: center;
  justify-content: stretch;
`;

const RadioIcon = styled.div<{ $selected: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${dimensions.size12};
  border: ${dimensions.size1} solid
    ${({ theme, $selected }) =>
      $selected
        ? theme.colors.border.accent[1000]
        : theme.colors.border.default[100]};
  border-radius: ${dimensions.size8};
`;

const RadioLabel = styled.div`
  ${textStyles.label.lb12sb}
  color: ${({ theme }) => theme.colors.text.header};
`;
