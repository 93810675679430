import { Dice, DiceRollAction, DiceRollStrategy } from '@common/studio-types';

const d100: Dice[] = ['d100'];
export const toDice = (showD100: boolean): Dice[] => [
  'd4',
  'd6',
  'd8',
  'd10',
  'd12',
  'd20',
  ...(showD100 ? d100 : []),
];

export const actions: {
  value: DiceRollAction;
  label: string;
  description: string;
}[] = [
  {
    value: DiceRollAction.User,
    label: 'User',
    description: 'The user will be requested to roll the dice.',
  },
  {
    value: DiceRollAction.AutoPlay,
    label: 'Auto Play',
    description: 'The engine will roll the dice and show the result.',
  },
  {
    value: DiceRollAction.Silent,
    label: 'Silent',
    description: "User won't know a dice was rolled.",
  },
];

export const strategies: {
  value: DiceRollStrategy;
  label: string;
  description: string;
}[] = [
  {
    value: DiceRollStrategy.Sum,
    label: 'Sum',
    description: 'Will return the sum of all rolled dice.',
  },
  {
    value: DiceRollStrategy.Highest,
    label: 'Highest',
    description: 'Will return the highest rolled dice.',
  },
  {
    value: DiceRollStrategy.Lowest,
    label: 'Lowest',
    description: 'Will return the lowest rolled dice.',
  },
  {
    value: DiceRollStrategy.ClassDifficulty,
    label: 'Difficulty Class',
    description:
      'Will return 0 or 1 based on the input difficulty class number.',
  },
];
