import { Tooltip } from '@chakra-ui/react';
import { EpisodeStatus } from '@maestro/cms/resources/episode/components/EpisodeStatus';
import { useEpisodeStatus } from '@maestro/cms/resources/episode/hooks/useEpisodeStatus';
import { Breadcrumb, TopBar, useMaestroToast } from '@maestro/components';
import { BaseSwitch } from '@maestro/components/BaseSwitch';
import { ShareButton } from '@maestro/components/ShareButton/ShareButton';
import { useFeatureFlagEvaluation } from '@maestro/feature-flags';
import { breakpoints } from '@maestro/styles';
import { useNavigation } from '@refinedev/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { EditEpisodeButton } from '../components/episode/EditEpisode/EditEpisodeButton';
import { EditSeriesButton } from '../components/series/EditSeries/EditSeriesButton';
import { useStudioFlowStore } from '../hooks/useStudioFlowStore';
import { useUserPreferencesStore } from '../state/userPreferences';
import { PlayEpisodeButton } from './PlayEpisodeButton';
import { StudioSaveStatus } from './StudioSaveStatus';
import { SuggestionsButton } from './SuggestionsButton';

export const StudioTopBar: React.FC = () => {
  const navigation = useNavigation();

  const { isRpgModeEnabled, toggleRpgMode } = useUserPreferencesStore(
    ({ isRpgModeEnabled, toggleRpgMode }) => ({
      isRpgModeEnabled,
      toggleRpgMode,
    }),
  );
  const { getFlagEvaluation } = useFeatureFlagEvaluation();
  const isAdvancedModeInSidebar = getFlagEvaluation('ENI-881');
  const toast = useMaestroToast();
  const { id: episodeId } = useParams();
  const { seriesId, seriesTitle, episodeName, episodeCoverUrl } =
    useStudioFlowStore();

  const {
    hasNewVersion,
    isDraft,
    isPublished,
    publishedAt,
    episodeOrder,
    refresh: refreshEpisodeStatus,
  } = useEpisodeStatus(episodeId!);

  const breadcrumb = [
    {
      label: (
        <EditSeriesButton
          episodeId={episodeId!}
          label={seriesTitle ?? ''}
          seriesId={seriesId!}
        />
      ),
    },
    {
      label: (
        <EditEpisodeButton
          episodeId={episodeId!}
          label={`E${episodeOrder ?? ''}: ${episodeName}`}
        />
      ),
    },
  ];

  const onRpgModeChange = (enabled: boolean) => {
    toggleRpgMode(enabled);
    toast({
      status: enabled ? 'success' : 'warning',
      title: `RPG components ${enabled ? 'enabled' : 'disabled'}`,
    });
  };

  return (
    <TopBar
      logoConfig={{
        onLogoClick: () => navigation.push(`/series/${seriesId}`),
        clickLocation: 'My Studio',
      }}
      breadcrumb={
        <HideOnMobile>
          <Breadcrumb
            breadcrumb={breadcrumb}
            additionalItems={
              <EpisodeStatus
                hasNewVersion={hasNewVersion}
                isDraft={isDraft}
                isPublished={isPublished}
                publishedAt={publishedAt}
                displayPublished={false}
              />
            }
          />
        </HideOnMobile>
      }
    >
      <StudioSaveStatus refreshStatus={refreshEpisodeStatus} />
      <SuggestionsButton />
      {!!episodeId && (
        <ShareButton
          episodeId={episodeId}
          episodeCoverUrl={episodeCoverUrl!}
          episodeTitle={episodeName!}
          canPublish={hasNewVersion || isDraft}
          variant="studioTopBar"
        />
      )}

      <PlayEpisodeButton />

      {!isAdvancedModeInSidebar && (
        <HideOnMobile>
          <Tooltip label="Toggle RPG components" placement="bottom-end">
            <div>
              <BaseSwitch
                checked={isRpgModeEnabled}
                onChange={(e) => onRpgModeChange(e.target.checked)}
              />
            </div>
          </Tooltip>
        </HideOnMobile>
      )}
    </TopBar>
  );
};

const HideOnMobile = styled.div`
  @media ${breakpoints.tablet} {
    display: none;
  }
`;
