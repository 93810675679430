import { useLDClient } from 'launchdarkly-react-client-sdk';

type UserContext = {
  email: string;
};

export const useFeatureFlagIdentifyUser = () => {
  const ldClient = useLDClient();

  const identifyUser = async (context: UserContext) => {
    try {
      await ldClient?.identify({ key: context.email });
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.log('Failed to identify user with LaunchDarkly');
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    identifyUser,
  };
};
