import { env } from '@maestro/env';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useFeatureFlagStore } from './hooks/useFeatureFlag';

export const ChildWrapper: FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

const RootProvider = withLDProvider({
  clientSideID: env.VITE_LAUNCH_DARKLY_CLIENT_ID,
  reactOptions: { useCamelCaseFlagKeys: false },
  options: {
    application: {
      id: 'maestro',
    },
    fetchGoals: false,
  },
})(ChildWrapper) as FC<PropsWithChildren>;

const InternalProvider: FC<PropsWithChildren> = ({ children }) => {
  const flags = useFlags();
  const setFlags = useFeatureFlagStore((state) => state.setFlags);

  useEffect(() => {
    if (flags) setFlags(flags);
  }, [flags]);

  return children;
};

export const FeatureFlagsProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <RootProvider>
      <InternalProvider>{children}</InternalProvider>
    </RootProvider>
  );
};
