import { LDFlagSet } from 'launchdarkly-react-client-sdk';
import { create } from 'zustand';
import { FeatureFlags } from '../featureFlags';

type FeatureFlagStore = {
  flags: LDFlagSet;
  setFlags(flags: LDFlagSet): void;
};

export const useFeatureFlagStore = create<FeatureFlagStore>()((set) => ({
  flags: {},
  setFlags: (flags) => set((state) => ({ ...state, flags })),
}));

/**
 * This is important to not cause re-rendering when another feature flag changes
 * @param id just the feature flag
 * @returns feature flag data
 */
export const useFeatureFlag = (flag: FeatureFlags) => {
  return useFeatureFlagStore((state) => state.flags[flag]);
};
